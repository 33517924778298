import { css } from '@emotion/react';

import { DOWNLOAD_LINK } from '@/marketing/constants/common';
import { AppStoreIcon, PlayStoreIcon, QrCode } from '@/marketing/constants/icon';
import { motion } from 'framer-motion';

export const QRScanBox = ({ completeWhite, isMobile }: { completeWhite?: boolean }) => {
    return (
        <motion.div initial={{ opacity: 0, y: 48 }} animate={{ opacity: 1, y: 0 }} css={qrBoxStyle} className={'flex'}>
            <div
                className={'p-6'}
                css={css`
                    border-right: 1px solid rgba(23, 23, 23, 0.15);
                    background: ${completeWhite ? '#fff' : 'linear-gradient(180deg, #4659FD 0%, #7d46fd 100%)'};
                `}
            >
                <QrCode color={completeWhite ? '#2c2b2b' : null} />
            </div>

            <div
                className={'flex flex-col justify-center p-12 pl-24 pr-40'}
                css={css`
                    color: ${completeWhite ? '#2c2b2b' : null};
                `}
            >
                <div className={'font mb-16 text-20 font-600 leading-none'}>Available on</div>
                <div className={'flex items-center justify-center  gap-8 text-15'}>
                    <a href={DOWNLOAD_LINK.ANDROID} target={'_blank'}>
                        <PlayStoreIcon />
                    </a>
                    <a href={DOWNLOAD_LINK.IOS} target={'_blank'}>
                        <AppStoreIcon />
                    </a>
                </div>
            </div>
        </motion.div>
    );
};
const qrBoxStyle = css`
    border-radius: 14px;
    border: 1px solid rgba(23, 23, 23, 0.15);
    background: #fff;
    /* box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1); */
    overflow: hidden;
    box-shadow: 0px 0px 0px 5px rgba(23, 23, 23, 0.04);
`;
