export const primaryMenuItems = [
    // {
    //     label: 'Support',
    //     link: '/about',
    // },
    // {
    //     label: 'Career',
    //     link: '/about',
    // },
    {
        label: 'Blog',
        link: 'https://blog.investmint.club',
    },
    {
        label: 'Press',
        link: 'https://invesmint.notion.site/Investmint-In-News-82a7d6c6cac14e91b724b1470cbe94ec',
    },
    {
        label: 'Careers',
        link: 'https://invesmint.notion.site/We-re-Hiring-e2f3bda72a674b4385441fdbf2de9e93',
    },
];
export const secondaryLinks = [
    // {
    //     label: 'Press',
    //     link: '/about',
    // },
    {
        label: 'T&C',
        link: '/terms-conditions',
    },
    {
        label: 'Privacy Policy',
        link: '/privacy-policy',
    },
];
