import { css } from '@emotion/react';

import { AppStoreIcon2, PlayStoreIcon } from '@/marketing/constants/icon';

export function PlayStoreButton({ isWhite = false }) {
    return (
        <div
            css={[
                googleButtonCSS,
                isWhite &&
                    css`
                        background: #fff;
                        color: #000;
                    `,
            ]}
            onClick={() => {
                window.open('https://investmint.onelink.me/TjeE/de6kc62t', '_blank');
            }}
            className={'flex items-center gap-12 text-16 font-600'}
        >
            <PlayStoreIcon />
            Download for Android
        </div>
    );
}

export function AppStoreButton({ isWhite = false }) {
    return (
        <div
            css={[
                googleButtonCSS,
                appStoreCSS,
                isWhite &&
                    css`
                        background: #fff;
                    `,
            ]}
            onClick={() => {
                window.open('https://apps.apple.com/us/app/investmint-invest-in-stocks/id1633434746', '_blank');
            }}
            className={' mt-16 flex items-center gap-12 text-16 font-600'}
        >
            <AppStoreIcon2 showDef={false} />
            Download on iPhone
        </div>
    );
}

const appStoreCSS = css`
    border: 1px solid rgba(0, 0, 0, 0.41);
    background: #fff;
    color: #202124;
    backdrop-filter: blur(3px);
`;
const googleButtonCSS = css`
    display: flex;
    width: 100%;
    height: 56px;

    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-left: auto;
    margin-right: auto;

    max-width: 380px;

    color: #fff;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.41);
    background: #191919;

    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.05);
`;
