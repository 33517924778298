import { css } from '@emotion/react';

export const WIDTH_1320_16_PADDING = css`
    max-width: 1208px;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    margin: 0 auto;
`;

export const WIDTH_1320_16_0_PADDING = css`
    max-width: 1208px;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    margin: 0 auto;
    @media screen and (max-width: 768px) {
        padding-left: 0px;
        padding-right: 0px;
    }
`;

export const CONTAINERS = {
    WIDTH_1320_16_PADDING,
    WIDTH_1320_16_0_PADDING,
};
