import { css } from '@emotion/react';

import Conditional from '@/ui/components/conditional';

import { QRScanBox } from '@/marketing/components/common/qrScanBox';
import { primaryMenuItems, secondaryLinks } from '@/marketing/components/homepage/footer/constants';
import { AppStoreButton, PlayStoreButton } from '@/marketing/components/homepage/hero/button';
import { InstaIcon, InvestmintLogo, LinkedinIcon, TwitterIcon, YoutubeIcon } from '@/marketing/constants/icon';
import { CONTAINERS } from '@/marketing/constants/styles';
import { motion } from 'framer-motion';

export const MenuSection = ({ isMobile = false }) => {
    return (
        <div
            css={css`
                grid-area: left;
            `}
        >
            <div
                className={`
             ${isMobile ? `mt-16 justify-center text-18` : `md:justify-center md:text-18`} 
            flex gap-28 text-16 tracking-1 md:justify-center md:text-18
            
            `}
            >
                {primaryMenuItems.map((item, index) => {
                    return (
                        <a key={index} className="flex justify-start" href={item.link} css={linkColor}>
                            {item.label}
                        </a>
                    );
                })}
            </div>

            <div
                className={`
                ${isMobile ? ` mt-16 justify-center text-16` : ``}
                mt-20 flex gap-28 text-16 tracking-1 md:justify-center md:text-16
                `}
            >
                {secondaryLinks.map((item, index) => {
                    return (
                        <a key={index} className="flex justify-start" href={item.link} css={linkColor}>
                            {item.label}
                        </a>
                    );
                })}
            </div>

            <div
                className={`mt-40 
            ${isMobile ? `flex flex-col items-center` : `md:flex md:flex-col md:items-center`} 
            md:flex md:flex-col md:items-center`}
            >
                <InvestmintLogo />

                <div className="mt-20 flex gap-20 md:mt-36 md:justify-center">
                    <a href="https://twitter.com/investmintclub" target="_blank">
                        <TwitterIcon />
                    </a>
                    <a href="https://www.instagram.com/investmint_club/" target="_blank">
                        <InstaIcon />
                    </a>
                    <a href="https://www.youtube.com/@investmintclub" target="_blank">
                        <YoutubeIcon />
                    </a>
                    <a href="https://in.linkedin.com/company/investmintclub" target="_blank">
                        <LinkedinIcon />
                    </a>
                </div>
            </div>
        </div>
    );
};

export const CompanyInfo = ({ isMobile = false }) => {
    return (
        <div
            css={css`
                grid-area: right;
            `}
        >
            <div
                className={` ${
                    isMobile ? `mt-40 flex-col items-center justify-center text-center text-16` : `flex w-full flex-col items-end text-16 leading-1.9`
                } 
            md:mt-4 md:flex-col md:items-center md:justify-center md:text-center md:text-16

            `}
            >
                <div>
                    <strong>Toolbox Technologies Private Limited</strong>
                    <br />
                    {/* Regd Office: JA 101, Salarpuria Greenage, <br />
                    Hosur Main Road, <br /> */}
                    Koramangala, Bengaluru
                    <br /> <br />
                    CIN: U72900KA2021PTC155011 <br />
                    Email: support@investmint.club <br />
                    Phone: 080-694-56800 <br />
                </div>
            </div>
        </div>
    );
};

export const CommonText = () => {
    return (
        <div className={'number-highlight mb-20 mt-2 text-center text-17 font-medium md:text-center md:text-22'}>
            Join{' '}
            <span
                className={'font-bold'}
                css={css`
                    color: #1350ed;
                `}
            >
                2,00,000+
            </span>{' '}
            traders upgrading their investing journey
        </div>
    );
};
export const QrCodeSection = ({ isMobile }) => {
    return (
        <div className="flex w-full flex-col items-center justify-start">
            <CommonText />
            <QRScanBox isMobile={isMobile} />
        </div>
    );
};

export const FooterSection = ({ isMobile, isDarkMode = false }) => {
    return (
        <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            whileInView="visible"
            viewport={{ once: true }}
            className="w-full"
            css={[
                footerContainer,
                isDarkMode &&
                    css`
                        border-top: 1px solid #1d1d1d39;
                        color: #1e1e1e;
                    `,
            ]}
        >
            <div
                className="flex items-start justify-center pt-90  pb-60 text-15 md:pt-52"
                css={[CONTAINERS.WIDTH_1320_16_PADDING, footerBox, isMobile && mobileBOXCSS]}
            >
                <MenuSection isMobile={isMobile} />

                <div
                    className={!isMobile ? 'show-only-desktop' : 'hidden'}
                    css={css`
                        grid-area: scan;
                        height: 100%;
                    `}
                >
                    <QrCodeSection isMobile={isMobile} />
                </div>

                <div
                    className={isMobile ? `mb-20` : 'show-only-mobile mb-20'}
                    css={css`
                        grid-area: scan;
                        height: 100%;
                    `}
                >
                    <CommonText />
                    <PlayStoreButton isWhite={true} />
                    <AppStoreButton />
                </div>
                <CompanyInfo isMobile={isMobile} />
            </div>
        </motion.div>
    );
};

const footerContainer = css`
    border-top: 1px solid #e0e0e0;
    width: 100%;
`;

const footerBox = css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0;

    grid-template-areas: 'left scan right';

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-areas: 'scan' 'left' 'right';
    }
`;

const mobileBOXCSS = css`
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas: 'scan' 'left' 'right';
`;

const linkColor = css`
    :hover {
        text-decoration: underline;
        text-decoration: underline;
        color: rgb(69, 88, 252);
    }
`;
